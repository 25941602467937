import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as z from 'zod'

import { Button, Input, SwitchButton } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { EUserStatus, REGEX_EMAIL, ROLE_DEFAULT_ID } from 'src/configs'
import { IRole, IStaffPayload, IUserDetail } from 'src/interfaces'
import { ShareSelectInput } from '../../shared'

interface IAddUpdateRoleModalProps {
  selectedStaff?: IUserDetail
  roles: IRole[]
  open: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: (data: any) => Promise<void>
}

const staffSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .regex(/^(?!.*[^a-zA-Z\s])\s*[a-zA-Z]+(?:\s+[a-zA-Z]+)*\s*$/, {
      message: t('error:name_format_error') as string,
    })
    .max(50, {
      message: t('error:name_staff_length_error'),
    })
    .min(2, {
      message: t('error:name_staff_length_error'),
    }),
  email: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .email({
      message: t('error:email_format_error') as string,
    })
    .regex(REGEX_EMAIL, {
      message: t('error:email_format_error') as string,
    })
    .max(50, {
      message: t('error:email_length_error') as string,
    }),
  phone: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(8, {
      message: t('error:phone_length_error') as string,
    })
    .max(13, {
      message: t('error:phone_length_error') as string,
    })
    .regex(/^\s*\d*\s*$|^$/, {
      message: t('error:phone_format_error') as string,
    }),
  roleId: z.string().nonempty({
    message: t('error:field_required') as string,
  }),
  status: z.string(),
})

const AddUpdateRoleModal = (props: IAddUpdateRoleModalProps) => {
  const { open, selectedStaff, isLoading, roles, onClose, onSubmit } = props

  const { control, handleSubmit, setValue } = useForm<IStaffPayload>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      roleId: '',
      status: EUserStatus.ACTIVE,
    },
    resolver: zodResolver(staffSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const isEditStaff = !!selectedStaff

  useEffect(() => {
    if (!!selectedStaff) {
      setValue('name', selectedStaff.name)
      setValue('email', selectedStaff.email)
      setValue('phone', selectedStaff.phone)
      setValue('roleId', selectedStaff.roleId.toString())
      setValue('status', selectedStaff.status as EUserStatus)
    }
  }, [selectedStaff])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {isEditStaff ? 'Edit staff' : 'Add new staff'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Name"
                    name="name"
                    alignment="col"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="email"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Email"
                    name="email"
                    alignment="col"
                    required={!isEditStaff}
                    disabled={isEditStaff}
                    onChange={(e) => {
                      if (!isEditStaff) {
                        onChange(e.target.value)
                      }
                    }}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="phone"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Phone"
                    name="phone"
                    alignment="col"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="roleId"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    required
                    containerClassName="flex flex-col gap-2"
                    labelClassName="col-span-2 flex items-center"
                    className="col-span-5 "
                    inlineLabelClassName="!block !m-0"
                    data={roles.map((role) => {
                      return {
                        label: role.name,
                        value: role.id?.toString() || '',
                      }
                    })}
                    onChange={(value: string) => {
                      onChange(value)
                    }}
                    label={'Role'}
                    placeholder='Select role'
                    errors={error?.message}
                    value={value}
                    style={{
                      height: '46px',
                      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                )
              }}
            />

            {/* <Controller
              name="status"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <div className="flex flex-col gap-2">
                    <div className="col-span-2 flex items-center">
                      <p className="mb-0 mr-4">Status</p>
                    </div>
                    <div className="col-span-5">
                      <SwitchButton
                        size="default"
                        checked={value === EUserStatus.ACTIVE}
                        onChange={(e) => {
                          onChange(
                            e ? EUserStatus.ACTIVE : EUserStatus.INACTIVE
                          )
                        }}
                      />
                    </div>
                  </div>
                )
              }}
            /> */}
          </div>

          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="middle"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

export default AddUpdateRoleModal
